import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import Container from "react-bootstrap/Container"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
      query {
          mission: directusMissionTranslation(language: {eq: "en"}) {
              title
              pre_headline
              content
              seo_description
              seo_title
              image {
                  localFile {
                      childImageSharp {
                          fluid {
                              ...GatsbyImageSharpFluid
                          }
                      }
                      publicURL
                      extension
                  }
              }
          }
      }
  `)

  return (
    <Layout>
      <SEO title={ data.mission.seo_title } description={ data.mission.seo_description } lang={"en"} url={"/mission"} />
      <Container fluid>
        <Row className={"site-content align-items-center"}>
          <Col md={5} className={"offset-md-1"}>
            <h4>{ data.mission.pre_headline }</h4>
            <h1>{ data.mission.title }</h1>
            <div dangerouslySetInnerHTML={{ __html:  data.mission.content }}/>
          </Col>
          <Col xs={12} sm={12} md={6} className={"p-0"}>
            <Img fluid={data.mission.image.localFile.childImageSharp.fluid} style={{ width: `100%` }} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
